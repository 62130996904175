import React, { useState } from "react";
import { UikDropdown, UikDropdownItem, UikDivider, UikButton } from "@uik";
import moment from "moment";

import styles from "./PaigeChat.module.scss";

const PaigeChat = (props) => {
  const { paigeChat } = props;

  const viewPaigeResponse = () => {
    console.log(paigeChat.response);
  };

  return (
    <div
      className={styles.container}
      onClick={() =>
        viewPaigeResponse()
      }
    >
      <div className={styles.userPrompt}>{paigeChat.prompt}</div>
      <div className={styles.paigeResponse}>{paigeChat.response}</div>
      <div className={styles.rowThree}>
        <div className={styles.expirationDate}>
          {moment(paigeChat.created_at).format("MMM D, YYYY")}
        </div>
      </div>
    </div>
  );
};

export default PaigeChat;
