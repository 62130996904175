import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import attachmentsIcon from " ../../images/icons/svg/attachments-icon.svg";
import commentsIcon from " ../../images/icons/svg/comment-icon.svg";
import referenceIcon from " ../../images/icons/svg/reference-icon.svg";
import versionsIcon from " ../../images/icons/svg/versions-icon.svg";
import shareLinkIcon from " ../../images/icons/svg/share-link-icon.svg";
import attachmentsIconGreen from " ../../images/icons/svg/attachments-icon-green.svg";
import commentsIconGreen from " ../../images/icons/svg/comment-icon-green.svg";
import referenceIconGreen from " ../../images/icons/svg/reference-icon-green.svg";
import versionsIconGreen from " ../../images/icons/svg/versions-icon-green.svg";
import shareLinkIconGreen from " ../../images/icons/svg/share-link-icon-teal.svg";
import claimsIcon from " ../../images/icons/svg/claims-icon.svg";
import claimsIconGreen from " ../../images/icons/svg/claims-icon-green.svg";
import templateIcon from " ../../images/icons/svg/template-icon-grey.svg";
import templateIconGreen from " ../../images/icons/svg/template-icon-green.svg";

import styles from "./SidePanelSection.module.scss";

/* UI Kit */
import { Uikon } from "@uik";

export default (props) => {
  const { clickAction, count, type } = props;

  let title;
  let icon;
  let greenIcon;
  let sectionId;

  if (type === "references") {
    title = "References";
    icon = referenceIcon;
    greenIcon = referenceIconGreen;
    sectionId = "reference-icon";
  } else if (type === "attachments") {
    title = "Attachments";
    icon = attachmentsIcon;
    greenIcon = attachmentsIconGreen;
    sectionId = "attachments-icon";
  } else if (type === "versions") {
    title = "Version History";
    icon = versionsIcon;
    greenIcon = versionsIconGreen;
    sectionId = "versions-icon";
  } else if (type === "comments") {
    title = "Comments";
    icon = commentsIcon;
    greenIcon = commentsIconGreen;
    sectionId = "comments-icon";
  } else if (type === "share_link") {
    title = "Share Links";
    icon = shareLinkIcon;
    greenIcon = shareLinkIconGreen;
    sectionId = "share-link-icon";
  } else if (type === "claims") {
    title = "Product Claims";
    icon = claimsIcon;
    greenIcon = claimsIconGreen;
    sectionId = "claims-icon";
  } else if (type === "template") {
    title = "Editable Fields";
    icon = templateIcon;
    greenIcon = templateIconGreen;
    sectionId = "template-icon";
  } else if (type === "editor") {
    title = "Editor";
    icon = templateIcon;
    greenIcon = templateIconGreen;
    sectionId = "template-icon";
  } else if (type === "paige_translations") {
    title = "Translations";
    icon = claimsIcon;
    greenIcon = claimsIconGreen;
    sectionId = "paige-translations-icon";
  } else if (type === "paige_chats") {
    title = "Ask Paige";
    icon = claimsIcon;
    greenIcon = claimsIconGreen;
    sectionId = "paige-chats-icon";
  }

  const [imgSrc, setImgSrc] = useState(icon);

  const mouseEnter = () => {
    setImgSrc(greenIcon);
  };

  const mouseLeave = () => {
    setImgSrc(icon);
  };

  return (
    <div className={styles.sectionContainer} id={type}>
      <div
        className={
          props.showMobileSidePanel
            ? styles.mobileSidePanelOptions
            : styles.sidePanelOptions
        }
        onMouseOver={mouseEnter}
        onMouseOut={mouseLeave}
      >
        <Link className={styles.approvalsLink} to={"#"} onClick={clickAction}>
          <div className={styles.approvals}>
            <span>
              <img id={sectionId} className={styles.icon} src={imgSrc} />
            </span>
            {(type === "paige_translations" || type === "paige_chats") && (
              <span className={styles.translationsSidePanelLabel}>{title}</span>
            )}
            {type !== "paige_translations" && type !== "paige_chats" && <span>{title}</span>}
          </div>
          {type !== "template" && type !== "editor" && (
            <div className={styles.approvalsRemaining}>
              <span id="versions__number-attachments">{count}</span>
            </div>
          )}
          {type === "editor" && <span className={styles.betaBadge}>Beta</span>}
          {type === "template" && (
            <span className={styles.editTemplate}>Edit</span>
          )}
          <div className="clear" />
        </Link>
      </div>
    </div>
  );
};
