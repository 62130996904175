import React, { useState } from "react";
import Select from "react-select";

import CloseSVG from "../../../../images/icons/svg/close.svg";
import Button from "../../../Common/Buttons/ButtonPrimary";

import styles from "./CreateModal.module.scss";

const CreateModal = (props) => {
  const { closeModal, createTranslation } = props;

  const [label, setLabel] = useState("");
  const [language, setLanguage] = useState("");
  const [sourceLanguage, setSourceLanguage] = useState("");

  const onLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const onLanguageChange = (e) => {
    setLanguage(e.value);
  };

  const onSourceLanguageChange = (e) => {
    setSourceLanguage(e.value);
  };

  const supportedLanguages = [
    { value: "BG", label: "Bulgarian" },
    { value: "ZH", label: "Chinese" },
    { value: "CS", label: "Czech" },
    { value: "DA", label: "Danish" },
    { value: "NL", label: "Dutch" },
    { value: "EN", label: "English" },
    { value: "ET", label: "Estonian" },
    { value: "FI", label: "Finnish" },
    { value: "FR", label: "French" },
    { value: "DE", label: "German" },
    { value: "EL", label: "Greek" },
    { value: "HU", label: "Hungarian" },
    { value: "ID", label: "Indonesian" },
    { value: "IT", label: "Italian" },
    { value: "JA", label: "Japanese" },
    { value: "KO", label: "Korean" },
    { value: "LV", label: "Latvian" },
    { value: "LT", label: "Lithuanian" },
    { value: "NB", label: "Norwegian" },
    { value: "PL", label: "Polish" },
    { value: "PT", label: "Portuguese" },
    { value: "RO", label: "Romanian" },
    { value: "RU", label: "Russian" },
    { value: "SK", label: "Slovak" },
    { value: "SL", label: "Slovenian" },
    { value: "ES", label: "Spanish" },
    { value: "SV", label: "Swedish" },
    { value: "TR", label: "Turkish" },
    { value: "UK", label: "Ukrainian" },
  ];

  return (
    <div className={styles.container}>
      <div className={styles.shadedAreaContainer} onClick={closeModal}></div>
      <div className={styles.modal}>
        <div className={styles.header}>
          <div className={styles.title}>New Translation</div>
          <div className={styles.closeButton} onClick={closeModal}>
            <img src={CloseSVG} />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.labelInput1}>
            <div className={styles.text}>Label</div>
            <input placeholder="Label" value={label} onChange={onLabelChange} />
            <br />
            <br />
            <div className={styles.text}>Translate</div>
            <Select
              className={styles.versionSelectField}
              placeholder="Source Language"
              options={supportedLanguages}
              onChange={onSourceLanguageChange}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: "#2c3041",
                  fontSize: "14px",
                  backgroundColor: "#2c3041",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  color: "#ffffff",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: "#ffffff",
                }),
                indicatorSeparator: (styles) => ({
                  ...styles,
                  backgroundColor: "#2c3041",
                }),
                group: (styles) => ({
                  ...styles,
                  borderColor: "#2c3041",
                }),
              }}
            />
            <div className={styles.textTo}>to</div>
            <Select
              className={styles.versionSelectField}
              placeholder="Destination Language"
              options={supportedLanguages}
              onChange={onLanguageChange}
              styles={{
                control: (styles) => ({
                  ...styles,
                  borderColor: "#2c3041",
                  fontSize: "14px",
                  backgroundColor: "#2c3041",
                }),
                singleValue: (styles) => ({
                  ...styles,
                  color: "#ffffff",
                }),
                placeholder: (styles) => ({
                  ...styles,
                  color: "#ffffff",
                }),
                indicatorSeparator: (styles) => ({
                  ...styles,
                  backgroundColor: "#2c3041",
                }),
                group: (styles) => ({
                  ...styles,
                  borderColor: "#2c3041",
                }),
              }}
            />
          </div>
        </div>
        <div className={styles.button}>
          <Button
            disabled={!label || label.match(/^\s+$/)}
            original
            text="Create Translation"
            onClick={() => createTranslation(label, sourceLanguage, language)}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateModal;
