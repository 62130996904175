import React, { useState } from "react";
import { EditorState, convertToRaw, ContentState, Modifier } from "draft-js";
import Editor from "draft-js-plugins-editor";

import CloseSVG from "../../../../images/icons/svg/close.svg";
import ShareLinkWhiteSVG from "../../../../images/icons/svg/share-link-icon-white.svg";
import httpClient from "../../../../lib/HttpClient";
import security from "../../../../services/Security";
import Button from "../../../Common/Buttons/ButtonPrimary";
import PaigeChat from "./PaigeChat";

import styles from "./PaigeChats.module.scss";

const PaigeChats = (props) => {
  const {
    paigeChats,
    numPaigeChats,
    docId,
    retrievePaigeChats,
    closePanel
  } = props;

  const [userRole] = useState(security.getUserRole());
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [askButtonDisabled, setAskButtonDisabled] = useState(false);
  const [askPaigeError, setAskPaigeError] = useState(null);

  const onChangeQuestion = (editorState) => {
    const text = editorState.getCurrentContent().getPlainText(); setEditorState(editorState);
  };

  const promptPaige = () => {
    if (editorState.getCurrentContent().getPlainText() === "") {
      return;
    }

    setAskButtonDisabled(true);

    const paigePrompt = editorState.getCurrentContent().getPlainText();
    const paigeChatPayload = {
      prompt: paigePrompt,
      document_id: docId
    };

    httpClient.post("/paige_chats.json", { paige_chat: paigeChatPayload })
      .then((response) => {
        console.log('response:', response);
        setEditorState(EditorState.createEmpty());
        setAskButtonDisabled(false);
        retrievePaigeChats(); // Refresh the chat list
      })
      .catch((error) => {
        console.error('Error creating chat:', error);
        setAskButtonDisabled(false);
        setAskPaigeError('Failed to send message. Please try again.');
      });
  };

  return (
    <React.Fragment>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.title}>Ask Paige</div>
          <div className={styles.closeButton} onClick={closePanel}>
            <img src={CloseSVG} />
          </div>
        </div>
        <div className={styles.body}>
          {paigeChats.length === 0 ? (
            <div className={styles.emptyStateContainer}>
              <div className={styles.emptyState}>
              <div className={styles.shareLinkIcon}>
                <img src={ShareLinkWhiteSVG} />
              </div>
              <div className={styles.text}>
                <div className={styles.textOne}>
                  Just Ask Paige
                </div>
                <div className={styles.textTwo}>
                  To deeply understand this document, just ask Paige Intelligence.
                  Simply post a question and Paige will try their best to answer it.<br /><br />
                  Some examples: "Summarize this document for me.", "What are some risks with this contract?"
                </div>
              </div>
              </div>
            </div>
          ) : (
            <React.Fragment>
              {paigeChats &&
                paigeChats.map((paigeChat) => (
                    <PaigeChat
                      key={paigeChat.id}
                      paigeChat={paigeChat}
                    />
                  ))}
            </React.Fragment>
          )}
        </div>
        <div className={styles.createNewShareLinkContainer}>
          <div className={styles.editor}>
            <Editor
              editorState={editorState}
              onChange={onChangeQuestion}
              placeholder="Type your question..."
            />
          </div>
          <Button
            original
            text="Ask Paige"
            onClick={promptPaige}
            disabled={askButtonDisabled}
            loading={askButtonDisabled}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default PaigeChats;
